<template>
    <CRow>
        <CModal
          :size="size"
          :title="titel"
          :color="color"
          :show="isOpen"        
          @update:show="setIsOpen"
        >

        <div slot="default" class="w-100">
          <slot name="image"></slot>

          <hr v-if="$slots.image">
          <slot name="body"></slot>
      
        </div>
                
        <div slot="footer" class="w-100">
            <CButton @click="setIsOpen(false)"  color="light" class="ml-1 mr-1 col-sm-2 float-right" >Close</CButton>  
        </div> 
        </CModal> 
    </CRow>
</template>


<script>


export default {
  components: {  },  
  data() {
    return { };
  },
  props: {
    titel: { 
      type: String,
      default: "TITEL PLACEHOLDER",
    },
    body: { 
      type: String,
      default: "BODY PLACEHOLDER",
    },
    isOpen: { 
      type: Boolean,
      default: false,
    },
    size: { 
      type: String,
      default: "",
    },
    color: { 
      type: String,
      default: "dark",
    },
  },
  emits: [
    'update'
  ],
  methods: {
    setIsOpen(value) {      
      this.$emit('update', value)
    }
  }

};

</script>

